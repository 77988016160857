import { LOCAL_STORAGE_DESKTOP_SIDEBAR } from '@/constants/local-storage';

export const state = () => ({
    mobileSidebarOpen: false,
    desktopSidebarOpen: typeof localStorage !== 'undefined' ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_DESKTOP_SIDEBAR)) : true,
});

export const mutations = {
    toggleMobileSidebar(state, value = null) {
        if(value !== null) {
            state.mobileSidebarOpen = !!value;
        } else {
            state.mobileSidebarOpen = !state.mobileSidebarOpen;
        }
    },
    toggleDesktopSidebar(state, value = null) {
        if(value !== null) {
            state.desktopSidebarOpen = !!value;
        } else {
            state.desktopSidebarOpen = !state.desktopSidebarOpen;
        }
        if(typeof localStorage !== 'undefined') {
            localStorage.setItem(LOCAL_STORAGE_DESKTOP_SIDEBAR, state.desktopSidebarOpen);
        }
    },
};

