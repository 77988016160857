import { render, staticRenderFns } from "./MobileSidebar.vue?vue&type=template&id=fcb2c346&"
import script from "./MobileSidebar.vue?vue&type=script&lang=js&"
export * from "./MobileSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/app/app/components/common/Logo.vue').default,MainMenu: require('/app/app/components/layout/MainMenu.vue').default,SecondaryMenu: require('/app/app/components/layout/SecondaryMenu.vue').default,UserMenu: require('/app/app/components/layout/UserMenu.vue').default})
