
import { mapState } from 'vuex';

export default {
    data() {
        return {
            open: false,
        };
    },
    computed: {
        ...mapState('auth', ['user']),
    },
};
