import { getIdTokenResult } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { ON_CALL_ENSURE_CLAIMS } from '~/constants/functions';

export default async ({ app: { store, $auth, $functions } }) => {
    console.debug('[MIDDLEWARE] Require hasura claims');

    return new Promise(async (resolve) => {
        if (!store.state.auth.isLoggedIn || !store.state.auth.user.uid) {
            return resolve(true);
        }

        if (!store.state.auth.claims['https://hasura.io/jwt/claims']) {
            if(process.env.APP_ENV !== 'production') console.debug('[CLAIMS] User does not have hasura claims');
            const onCallEnsureClaims = httpsCallable($functions, ON_CALL_ENSURE_CLAIMS);

            await onCallEnsureClaims({
                uid: store.state.auth.user.uid
            });

            await refreshToken($auth.currentUser, store);
        }

        return resolve(true);
    });
}

const refreshToken = async (user, store) => {
    const jwt = await getIdTokenResult(user, true);
    return store.dispatch('auth/login', { user, jwt });
}
