import get from 'lodash/get';
import { getLocallySignedToken } from '~/utilities/jwt';
import { getIdTokenResult, signOut } from 'firebase/auth';
import FetchProfile from '~/graphql/queries/users/FetchProfile.gql';

const emptyUser = {
    uid: null,
    displayName: '',
    photoURL: null,
    email: '',
    emailVerified: false,
};

export const state = () => ({
    isLoggedIn: false,
    token: null,
    claims: {},
    user: { ...emptyUser },
    profile: null,
});

export const getters = {
    token: state => state.token,
};

export const actions = {
    async checkExpiredToken({ commit, dispatch, state }, forceRefresh = false) {
        if(!this.$auth || !this.$auth.currentUser) return false;

        const result = await getIdTokenResult(this.$auth.currentUser, forceRefresh);
        const token = process.env.APP_ENV === 'development'
            ? getLocallySignedToken(result.token)
            : result.token;

        if(token !== state.token) {
            if(process.env.APP_ENV !== 'production') {
                console.groupCollapsed('checkExpiredToken');
                console.log(token !== state.token ? 'New token' : 'Same token');
                console.log(result.claims);
                console.groupEnd();
            }

            await dispatch('login', {
                user: this.$auth.currentUser,
                token: result,
            });
        }

        return token;
    },
    async expiredToken({ dispatch }) {
        if(process.env.APP_ENV !== 'production') console.log('expiredToken');

        return dispatch('logout');
    },
    async login({ commit, dispatch }, { user, jwt }) {
        if(!user || !jwt) return dispatch('expiredToken');

        const { token, claims } = jwt;
        const freshToken = process.env.APP_ENV !== 'development'
            ? token !== state.token
            : getLocallySignedToken(token) !== state.token;

        if(process.env.APP_ENV !== 'production') {
            console.groupCollapsed('login');
            console.log(token !== freshToken ? 'New token' : 'Same token');
            console.log(claims);
            console.debug(`[DEV ONLY][RAW]`, token);
            console.debug(`[DEV ONLY][HASURA]`, getLocallySignedToken(token));
            console.groupEnd();
        }

        commit('setToken', jwt);
        commit('setIsLoggedIn', true);
        commit('setUser', user);

        this.$axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        return dispatch('fetchProfile');
    },
    async logout() {
        await signOut(this.$auth);

        return this.$router.replace({ name: 'auth-login' });
    },
    async onLogout({ commit, state }) {
        if(process.env.APP_ENV !== 'production') console.log('auth/onLogout');

        commit('setIsLoggedIn', false);
        commit('resetUser');
        commit('resetToken');
        commit('resetProfile');

        delete this.$axios.defaults.headers.common['Authorization'];
    },
    async fetchProfile({ commit, state }) {
        const apolloProvider = this.app.apolloProvider;
        const claims = get(state.claims, 'https://hasura.io/jwt/claims', null);
        const userId = get(claims, 'x-hasura-user-id', null);

        if (userId) {
            const response = await apolloProvider.defaultClient.query({
                fetchPolicy: 'no-cache',
                query: FetchProfile,
                variables: {
                    id: userId,
                },
            }).catch((error) => {
                if(this.$sentry) {
                    this.$sentry.captureException(error);
                }
            });

            if(response && response.data) {
                commit('setProfile', response.data.profile);
            }

            return response;
        }
    }
};

export const mutations = {
    setToken(state, jwt) {
        const { token, claims } = jwt;
        state.token = process.env.APP_ENV === 'development' ? getLocallySignedToken(token) : token;
        state.claims = claims;
    },
    resetToken(state) {
        state.token = null;
        state.claims = {};
    },
    setIsLoggedIn(state, isLoggedIn = false) {
        state.isLoggedIn = isLoggedIn;
    },
    setUser(state, user) {
        state.user = {
            uid: user.uid,
            displayName: user.displayName || state.claims && state.claims.name ? state.claims.name : '',
            photoURL: user.photoURL || null,
            email: user.email,
            emailVerified: user.emailVerified,
        };
    },
    resetUser(state) {
        state.user = { ...emptyUser };
    },
    setProfile(state, profile) {
        state.profile = profile;
    },
    resetProfile(state) {
        state.profile = null;
    },
};
