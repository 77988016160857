
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    props: {
        header: {
            type: Boolean,
            default: () => false,
        },
        footer: {
            type: Boolean,
            default: () => false,
        },
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    methods: {
        ...mapMutations('ui', ['toggleMobileSidebar']),
        ...mapActions('auth', ['logout']),
    }
};
