import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export class Storage {
    constructor($storage) {
        this.storage = $storage;
    }

    /**
     * Upload file
     * @param file
     * @param path
     * @param customMetadata
     * @returns {Promise<*>}
     */
    async upload({ file, path, customMetadata = {} }) {
        const reference = ref(this.storage, `${path}/${file.uuid}`);

        const snapshot = await uploadBytes(reference, file.raw, {
            name: file.name,
            size: file.size,
            contentType: file.type,
            customMetadata
        });

        file.url = await getDownloadURL(snapshot.ref);

        return file;
    }

    /**
     * Upload a new avatar
     * @param user
     * @param file
     * @returns {Promise<string>}
     */
    async uploadAvatar({ user, file }) {
        const reference = ref(this.storage, `records/${user.uid}/${user.uid}`);

        const snapshot = await uploadBytes(reference, file.raw);

        return getDownloadURL(snapshot.ref);
    }
}
