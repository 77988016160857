import Vue from 'vue';
import {
    Alert,
    Badge,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    DatePicker,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Form,
    FormItem,
    Icon,
    Input,
    InputNumber,
    Loading,
    Menu,
    Message,
    MessageBox,
    Option,
    OptionGroup,
    Popover,
    Radio,
    RadioButton,
    RadioGroup,
    Upload,
    Select,
    Submenu,
    Switch,
    Tag,
    Tooltip,
    TimePicker,
    TimeSelect,
} from 'element-ui';

import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

locale.use(lang);

Vue.use(Alert);
Vue.use(Badge);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(DatePicker);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Icon);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Menu);
Vue.use(Popover);
Vue.use(Radio);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(Upload);
Vue.use(Select);
Vue.use(Submenu);
Vue.use(Switch);
Vue.use(Tag);
Vue.use(Tooltip);
Vue.use(TimePicker);
Vue.use(TimeSelect);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
// Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
// Vue.use(ElementUI, { locale });
