
export default {
    layout: 'full',
    props: ['error'],
    head() {
        return {
            title: this.error.statusCode,
        }
    },
    computed: {
        isDevelopment() {
            return process.env.APP_ENV === 'development';
        }
    },
    data() {
        return {
            popularPages: [
                {
                    icon: 'fa-fw fa-regular fa-home',
                    title: 'Home',
                    description: 'Make mental health part of your daily routine.',
                    route: { name: 'index' }
                },
                {
                    icon: 'fa-fw fa-regular fa-compass',
                    title: 'Explore',
                    description: 'Find evidence-based activities curated by our partners and us.',
                    route: { name: 'explore' }
                },
                {
                    icon: 'fa-fw fa-regular fa-home-heart',
                    title: 'Care teams',
                    description: 'Collaborate with your care teams easily via Speakbox.',
                    route: { name: 'care-teams' }
                }
            ]
        }
    },
    methods: {
        goBack() {
            this.$router.replace({ name: 'index' });
        }
    }
};
