import { LOCAL_STORAGE_REDIRECT_AFTER_EMAIL_VERIFICATION } from '~/constants/local-storage';

export default async ({ store, redirect, route, app: { $sentry } }) => {
    console.debug('[MIDDLEWARE] Require a verified email');

    try {
        if(route.matched.length && !route.path.includes('auth') && store.state.auth.isLoggedIn && !store.state.auth.user.emailVerified) {
            try {
                if(typeof localStorage !== 'undefined') {
                    localStorage.setItem(LOCAL_STORAGE_REDIRECT_AFTER_EMAIL_VERIFICATION, JSON.stringify(route));
                }

                return redirect({ name: 'verify-email' });
            } catch(error) {
                console.warn(error);
                $sentry.captureException(error);
            }
        }
    } catch(error) {
        $sentry.captureException(error, {
            tags: {
                location: 'middleware',
                middleware: 'require-verified-email'
            }
        })
    }
}
