
import { mapState } from 'vuex';

export default {
    middleware: ['auth', 'require-verified-email'],
    computed: {
        ...mapState('auth', ['isLoggedIn', 'user']),
        isDevelopment() {
            return process.env.APP_ENV === 'development';
        },
    },
};
