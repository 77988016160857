import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1f2fc088 = () => interopDefault(import('../app/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _e51429d6 = () => interopDefault(import('../app/pages/care-teams/index.vue' /* webpackChunkName: "pages/care-teams/index" */))
const _c8b12d24 = () => interopDefault(import('../app/pages/explore/index.vue' /* webpackChunkName: "pages/explore/index" */))
const _d889797e = () => interopDefault(import('../app/pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _5f3e9504 = () => interopDefault(import('../app/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _129240bd = () => interopDefault(import('../app/pages/providers/index.vue' /* webpackChunkName: "pages/providers/index" */))
const _4f4dce58 = () => interopDefault(import('../app/pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _d87e4894 = () => interopDefault(import('../app/pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _18f47b4c = () => interopDefault(import('../app/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _8df5ff7c = () => interopDefault(import('../app/pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _f24472c4 = () => interopDefault(import('../app/pages/verify/email.vue' /* webpackChunkName: "pages/verify/email" */))
const _416d05ea = () => interopDefault(import('../app/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _221d79c8 = () => interopDefault(import('../app/pages/rooms/appointments/_appointment.vue' /* webpackChunkName: "pages/rooms/appointments/_appointment" */))
const _7159701a = () => interopDefault(import('../app/pages/appointments/_appointment.vue' /* webpackChunkName: "pages/appointments/_appointment" */))
const _5f88787f = () => interopDefault(import('../app/pages/care-teams/_team.vue' /* webpackChunkName: "pages/care-teams/_team" */))
const _515b387c = () => interopDefault(import('../app/pages/care-teams/_team/index.vue' /* webpackChunkName: "pages/care-teams/_team/index" */))
const _6ef85c77 = () => interopDefault(import('../app/pages/care-teams/_team/appointments/index.vue' /* webpackChunkName: "pages/care-teams/_team/appointments/index" */))
const _5596d52e = () => interopDefault(import('../app/pages/care-teams/_team/billing/index.vue' /* webpackChunkName: "pages/care-teams/_team/billing/index" */))
const _040c84a9 = () => interopDefault(import('../app/pages/care-teams/_team/book.vue' /* webpackChunkName: "pages/care-teams/_team/book" */))
const _aa0f7c56 = () => interopDefault(import('../app/pages/care-teams/_team/care-plan.vue' /* webpackChunkName: "pages/care-teams/_team/care-plan" */))
const _26b4011c = () => interopDefault(import('../app/pages/care-teams/_team/consents/index.vue' /* webpackChunkName: "pages/care-teams/_team/consents/index" */))
const _8beaca32 = () => interopDefault(import('../app/pages/care-teams/_team/files.vue' /* webpackChunkName: "pages/care-teams/_team/files" */))
const _0ce5b83c = () => interopDefault(import('../app/pages/care-teams/_team/forms/index.vue' /* webpackChunkName: "pages/care-teams/_team/forms/index" */))
const _67e157f4 = () => interopDefault(import('../app/pages/care-teams/_team/notes/index.vue' /* webpackChunkName: "pages/care-teams/_team/notes/index" */))
const _4d653bdf = () => interopDefault(import('../app/pages/care-teams/_team/personal-information.vue' /* webpackChunkName: "pages/care-teams/_team/personal-information" */))
const _2fcd40da = () => interopDefault(import('../app/pages/care-teams/_team/practice-information.vue' /* webpackChunkName: "pages/care-teams/_team/practice-information" */))
const _6edb35f3 = () => interopDefault(import('../app/pages/care-teams/_team/questionnaires/index.vue' /* webpackChunkName: "pages/care-teams/_team/questionnaires/index" */))
const _aa116c6a = () => interopDefault(import('../app/pages/care-teams/_team/appointments/_appointment.vue' /* webpackChunkName: "pages/care-teams/_team/appointments/_appointment" */))
const _9b57c77c = () => interopDefault(import('../app/pages/care-teams/_team/billing/_invoice.vue' /* webpackChunkName: "pages/care-teams/_team/billing/_invoice" */))
const _4b4ff033 = () => interopDefault(import('../app/pages/care-teams/_team/forms/_form.vue' /* webpackChunkName: "pages/care-teams/_team/forms/_form" */))
const _1196f953 = () => interopDefault(import('../app/pages/care-teams/_team/goals/_goal.vue' /* webpackChunkName: "pages/care-teams/_team/goals/_goal" */))
const _08b2ff1a = () => interopDefault(import('../app/pages/care-teams/_team/notes/_note.vue' /* webpackChunkName: "pages/care-teams/_team/notes/_note" */))
const _7ca9f6d3 = () => interopDefault(import('../app/pages/care-teams/_team/questionnaires/_questionnaire.vue' /* webpackChunkName: "pages/care-teams/_team/questionnaires/_questionnaire" */))
const _cc1e6fb4 = () => interopDefault(import('../app/pages/explore/_slug.vue' /* webpackChunkName: "pages/explore/_slug" */))
const _5b71e4e2 = () => interopDefault(import('../app/pages/invoices/_invoice.vue' /* webpackChunkName: "pages/invoices/_invoice" */))
const _abe26604 = () => interopDefault(import('../app/pages/providers/_provider/index.vue' /* webpackChunkName: "pages/providers/_provider/index" */))
const _4692c4a4 = () => interopDefault(import('../app/pages/providers/_provider/join.vue' /* webpackChunkName: "pages/providers/_provider/join" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _1f2fc088,
    name: "account"
  }, {
    path: "/care-teams",
    component: _e51429d6,
    name: "care-teams"
  }, {
    path: "/explore",
    component: _c8b12d24,
    name: "explore"
  }, {
    path: "/profile",
    component: _d889797e,
    children: [{
      path: "",
      component: _5f3e9504,
      name: "profile"
    }]
  }, {
    path: "/providers",
    component: _129240bd,
    name: "providers"
  }, {
    path: "/verify",
    component: _4f4dce58,
    name: "verify"
  }, {
    path: "/auth/forgot-password",
    component: _d87e4894,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _18f47b4c,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _8df5ff7c,
    name: "auth-register"
  }, {
    path: "/verify/email",
    component: _f24472c4,
    name: "verify-email"
  }, {
    path: "/",
    component: _416d05ea,
    name: "index"
  }, {
    path: "/rooms/appointments/:appointment?",
    component: _221d79c8,
    name: "rooms-appointments-appointment"
  }, {
    path: "/appointments/:appointment?",
    component: _7159701a,
    name: "appointments-appointment"
  }, {
    path: "/care-teams/:team?",
    component: _5f88787f,
    children: [{
      path: "",
      component: _515b387c,
      name: "care-teams-team"
    }, {
      path: "appointments",
      component: _6ef85c77,
      name: "care-teams-team-appointments"
    }, {
      path: "billing",
      component: _5596d52e,
      name: "care-teams-team-billing"
    }, {
      path: "book",
      component: _040c84a9,
      name: "care-teams-team-book"
    }, {
      path: "care-plan",
      component: _aa0f7c56,
      name: "care-teams-team-care-plan"
    }, {
      path: "consents",
      component: _26b4011c,
      name: "care-teams-team-consents"
    }, {
      path: "files",
      component: _8beaca32,
      name: "care-teams-team-files"
    }, {
      path: "forms",
      component: _0ce5b83c,
      name: "care-teams-team-forms"
    }, {
      path: "notes",
      component: _67e157f4,
      name: "care-teams-team-notes"
    }, {
      path: "personal-information",
      component: _4d653bdf,
      name: "care-teams-team-personal-information"
    }, {
      path: "practice-information",
      component: _2fcd40da,
      name: "care-teams-team-practice-information"
    }, {
      path: "questionnaires",
      component: _6edb35f3,
      name: "care-teams-team-questionnaires"
    }, {
      path: "appointments/:appointment",
      component: _aa116c6a,
      name: "care-teams-team-appointments-appointment"
    }, {
      path: "billing/:invoice",
      component: _9b57c77c,
      name: "care-teams-team-billing-invoice"
    }, {
      path: "forms/:form",
      component: _4b4ff033,
      name: "care-teams-team-forms-form"
    }, {
      path: "goals/:goal?",
      component: _1196f953,
      name: "care-teams-team-goals-goal"
    }, {
      path: "notes/:note",
      component: _08b2ff1a,
      name: "care-teams-team-notes-note"
    }, {
      path: "questionnaires/:questionnaire",
      component: _7ca9f6d3,
      name: "care-teams-team-questionnaires-questionnaire"
    }]
  }, {
    path: "/explore/:slug",
    component: _cc1e6fb4,
    name: "explore-slug"
  }, {
    path: "/invoices/:invoice?",
    component: _5b71e4e2,
    name: "invoices-invoice"
  }, {
    path: "/providers/:provider",
    component: _abe26604,
    name: "providers-provider"
  }, {
    path: "/providers/:provider/join",
    component: _4692c4a4,
    name: "providers-provider-join"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
