
import { mapState, mapMutations } from 'vuex';
import FetchTeams from '~/graphql/subscriptions/teams/FetchTeams';

export default {
    props: {
        header: {
            type: Boolean,
            default: () => false,
        },
        footer: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            teams: []
        }
    },
    computed: {
        ...mapState('auth', ['isLoggedIn', 'user']),
    },
    apollo: {
        $subscribe: {
            teams: {
                query: FetchTeams,
                variables() {
                    return {
                        id: this.user.uid
                    };
                },
                result({ data }) {
                    this.teams = data.teams || [];
                },
                skip() {
                    return !this.isLoggedIn;
                }
            }
        }
    },
    methods: {
        ...mapMutations('ui', ['toggleMobileSidebar']),
    }
};
