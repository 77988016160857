import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps'

export default () => {
    Vue.use(VueGoogleMaps, {
        load: {
            key: process.env.GOOGLE_PLACES_API_KEY,
        }
    });
}
