import { render, staticRenderFns } from "./DesktopSidebar.vue?vue&type=template&id=2f211b54&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/app/app/components/common/Logo.vue').default,MainMenu: require('/app/app/components/layout/MainMenu.vue').default,SecondaryMenu: require('/app/app/components/layout/SecondaryMenu.vue').default,UserMenu: require('/app/app/components/layout/UserMenu.vue').default})
