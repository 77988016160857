import storage from '~/api/storage';
import { getIdTokenResult } from 'firebase/auth';

const httpAPIs = {
    billing: process.env.BILLING_API
};

export default ({ $axios, store, app }, inject) => {
    const httpAPIs = http($axios, store, app)
    const storageAPIs = storage(app);

    inject('api', {
        http: httpAPIs,
        storage: storageAPIs,
    });
}

const http = ($axios, store, { $auth }) => {
    const addAuthorizationHeader = async (config) => {
        const token = await getIdTokenResult($auth.currentUser);

        config.headers.Authorization = `Bearer ${token.token}`;

        return config;
    };

    const result = {};

    for (const api in httpAPIs) {
        const endpoint = httpAPIs[api];

        const tmp = $axios.create();

        tmp.setBaseURL(endpoint);
        tmp.onRequest(addAuthorizationHeader);

        result[api] = tmp;
    }

    return result;
}
