import Vue from 'vue';
import get from 'lodash/get';
import VueApollo from 'vue-apollo';
import { createClient } from "graphql-ws";
import { ApolloClient, from, split, InMemoryCache, HttpLink } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";

const options = {
    httpUri: process.env.GRAPHQL_ENDPOINT,
    wsUri: process.env.WS_GRAPHQL_ENDPOINT,
};

export default ({ app, store, error, $sentry }) => {
    const getHeaders = () => {
        // const headers = {};
        //
        // headers.authorization = store.state.auth.token ? `Bearer ${store.state.auth.token}` : null;
        //
        // return headers;
        return {
            ...(store.state.auth.token && { authorization: `Bearer ${store.state.auth.token}`})
        }
    };

    const errorHandler = onError(({ operation, response, graphQLErrors, networkError, forward }) => {
        if (networkError) {
            $sentry.withScope((scope) => {
                scope.setExtra('Operation', operation.operationName);
                scope.setExtra('Query', get(operation, 'query.loc.source.body', ''));
                $sentry.captureMessage(`GraphQL: ${networkError.message || 'Network Error'}`, 'error');
            });

            if (networkError.message.includes('JWTExpired')) {
                store.dispatch('auth/expiredToken').finally(() => false);
                return;
            }

            error({
                title: 'GraphQL Error',
                message: networkError.message !== 'undefined' ? networkError.message : 'Unknown error',
                statusCode: networkError.code || 500
            });

            return;
        }

        if (graphQLErrors) {
            $sentry.withScope((scope) => {
                scope.setExtra('Operation', operation.operationName);
                scope.setExtra('Query', get(operation, 'query.loc.source.body', ''));
                for (let error of graphQLErrors) {
                    $sentry.captureMessage(`GraphQL: ${error.message || 'unknown error'}`, 'error');
                }
            });
        }

        return forward(operation);
    });

    const retryLink = new RetryLink({
        delay: {
            initial: 500,
            max: Infinity,
            jitter: false
        },
        attempts: async (count, operation, error) => {
            if (!!error && error.message.includes('JWTExpired')) {
                if (process.env.APP_ENV !== 'production') console.debug('RetryLink: JWTExpired');
                await store.dispatch('auth/checkExpiredToken', true);
                operation.setContext({ headers: await getHeaders() });
                return count <= 1;
            }

            return false;
        }
    });

    const httpLink = new HttpLink({
        uri: options.httpUri,
        credentials: 'include',
    });

    const wsClient = createClient({
        url: options.wsUri,
        lazy: true,
        on: {
            connected() {
                if (process.env.APP_ENV !== 'production') console.debug('[WEBSOCKET] Connected to GraphQL WS');
            },
            opened() {
                if (process.env.APP_ENV !== 'production') console.debug('[WEBSOCKET] Opened');
            },
            closed() {
                if (process.env.APP_ENV !== 'production') console.debug('[WEBSOCKET] Closed');
            },
            message(message) {
                if (process.env.APP_ENV !== 'production') console.debug('[WEBSOCKET] Message to GraphQL WS', message);
            },
            error(error) {
                if (process.env.APP_ENV !== 'production') console.debug('[WEBSOCKET] Error on GraphQL WS', error);
                $sentry.captureMessage('Websocket: Error', 'error', error);
            }
        },
        connectionParams: () => ({
            headers: getHeaders(),
        }),
    });

    const wsLink = new GraphQLWsLink(wsClient);

    const splitLink = split(({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    }, wsLink, httpLink);

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                ...getHeaders()
            }
        };
    });

    const cache = new InMemoryCache();

    const client = new ApolloClient(({
        link: from([errorHandler, retryLink, authLink, splitLink]),
        cache
    }));

    Vue.use(VueApollo);

    app.apolloProvider = new VueApollo({
        defaultClient: client,
    });
}
