const middleware = {}

middleware['auth'] = require('../app/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['guest'] = require('../app/middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['initialize-auth'] = require('../app/middleware/initialize-auth.js')
middleware['initialize-auth'] = middleware['initialize-auth'].default || middleware['initialize-auth']

middleware['require-claims'] = require('../app/middleware/require-claims.js')
middleware['require-claims'] = middleware['require-claims'].default || middleware['require-claims']

middleware['require-verified-email'] = require('../app/middleware/require-verified-email.js')
middleware['require-verified-email'] = middleware['require-verified-email'].default || middleware['require-verified-email']

middleware['version'] = require('../app/middleware/version.js')
middleware['version'] = middleware['version'].default || middleware['version']

export default middleware
