import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config';
import featureFlags from '~/featureFlags';

const isDevelopment = process.env.APP_ENV === 'development';
const disableWarnings = true;

export default async (context, inject) => {
    if(process.env.APP_ENV !== 'production') console.debug('[PLUGIN] firebase.js');
    const config = {
        apiKey: process.env.FIREBASE_API_KEY,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.FIREBASE_DATABASE_URL,
        projectId: process.env.FIREBASE_PROJECT_ID,
        storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.FIREBASE_APP_ID,
        measurementId: process.env.FIREBASE_MEASUREMENT_ID,
    };

    const app = initializeApp(config);
    const auth = getAuth(app);
    const firestore = getFirestore(app);
    const functions = getFunctions(app);
    const storage = getStorage(app);
    const remoteConfig = getRemoteConfig(app);

    if (isDevelopment) {
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings });
        connectFirestoreEmulator(firestore, 'localhost', 7979);
        connectFunctionsEmulator(functions, 'localhost', 5001);
        connectStorageEmulator(storage, 'localhost', 9199);
        remoteConfig.settings.minimumFetchIntervalMillis = 30 * 1000; // 30 seconds
    } else {
        firestore._settings.experimentalForceLongPolling = true;
        remoteConfig.settings.minimumFetchIntervalMillis = 60 * 60 * 1000; // 1 hour
    }

    await setPersistence(auth, browserLocalPersistence);

    remoteConfig.defaultConfig = featureFlags;

    await fetchAndActivate(remoteConfig);

    inject('firebase', app);
    inject('auth', auth);
    inject('firestore', firestore);
    inject('functions', functions);
    inject('storage', storage);
    inject('remoteConfig', remoteConfig);
    inject('featureFlag', (key) => {
        return getValue(remoteConfig, key);
    });
};
