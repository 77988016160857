export const SxAlert = () => import('../../node_modules/@speakbox/ui-core/components/Alert.vue' /* webpackChunkName: "components/sx-alert" */).then(c => wrapFunctional(c.default || c))
export const SxAvatar = () => import('../../node_modules/@speakbox/ui-core/components/Avatar.vue' /* webpackChunkName: "components/sx-avatar" */).then(c => wrapFunctional(c.default || c))
export const SxButton = () => import('../../node_modules/@speakbox/ui-core/components/Button.vue' /* webpackChunkName: "components/sx-button" */).then(c => wrapFunctional(c.default || c))
export const SxDate = () => import('../../node_modules/@speakbox/ui-core/components/Date.vue' /* webpackChunkName: "components/sx-date" */).then(c => wrapFunctional(c.default || c))
export const SxDivider = () => import('../../node_modules/@speakbox/ui-core/components/Divider.vue' /* webpackChunkName: "components/sx-divider" */).then(c => wrapFunctional(c.default || c))
export const SxDropdown = () => import('../../node_modules/@speakbox/ui-core/components/Dropdown.vue' /* webpackChunkName: "components/sx-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SxDropdownItem = () => import('../../node_modules/@speakbox/ui-core/components/DropdownItem.vue' /* webpackChunkName: "components/sx-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const SxEmptyState = () => import('../../node_modules/@speakbox/ui-core/components/EmptyState.vue' /* webpackChunkName: "components/sx-empty-state" */).then(c => wrapFunctional(c.default || c))
export const SxEmptyStateWithAction = () => import('../../node_modules/@speakbox/ui-core/components/EmptyStateWithAction.vue' /* webpackChunkName: "components/sx-empty-state-with-action" */).then(c => wrapFunctional(c.default || c))
export const SxFlyout = () => import('../../node_modules/@speakbox/ui-core/components/Flyout.vue' /* webpackChunkName: "components/sx-flyout" */).then(c => wrapFunctional(c.default || c))
export const SxLink = () => import('../../node_modules/@speakbox/ui-core/components/Link.vue' /* webpackChunkName: "components/sx-link" */).then(c => wrapFunctional(c.default || c))
export const SxLoader = () => import('../../node_modules/@speakbox/ui-core/components/Loader.vue' /* webpackChunkName: "components/sx-loader" */).then(c => wrapFunctional(c.default || c))
export const SxModal = () => import('../../node_modules/@speakbox/ui-core/components/Modal.vue' /* webpackChunkName: "components/sx-modal" */).then(c => wrapFunctional(c.default || c))
export const SxModalFullscreen = () => import('../../node_modules/@speakbox/ui-core/components/ModalFullscreen.vue' /* webpackChunkName: "components/sx-modal-fullscreen" */).then(c => wrapFunctional(c.default || c))
export const SxPaginationMonth = () => import('../../node_modules/@speakbox/ui-core/components/PaginationMonth.vue' /* webpackChunkName: "components/sx-pagination-month" */).then(c => wrapFunctional(c.default || c))
export const SxPhoneInput = () => import('../../node_modules/@speakbox/ui-core/components/PhoneInput.vue' /* webpackChunkName: "components/sx-phone-input" */).then(c => wrapFunctional(c.default || c))
export const SxPhoneInputInternational = () => import('../../node_modules/@speakbox/ui-core/components/PhoneInputInternational.vue' /* webpackChunkName: "components/sx-phone-input-international" */).then(c => wrapFunctional(c.default || c))
export const SxSignature = () => import('../../node_modules/@speakbox/ui-core/components/Signature.vue' /* webpackChunkName: "components/sx-signature" */).then(c => wrapFunctional(c.default || c))
export const SxTabs = () => import('../../node_modules/@speakbox/ui-core/components/Tabs.vue' /* webpackChunkName: "components/sx-tabs" */).then(c => wrapFunctional(c.default || c))
export const AddressField = () => import('../../app/components/common/AddressField.vue' /* webpackChunkName: "components/address-field" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbItem = () => import('../../app/components/common/BreadcrumbItem.vue' /* webpackChunkName: "components/breadcrumb-item" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../app/components/common/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../app/components/common/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const ComingSoon = () => import('../../app/components/common/ComingSoon.vue' /* webpackChunkName: "components/coming-soon" */).then(c => wrapFunctional(c.default || c))
export const EditableField = () => import('../../app/components/common/EditableField.vue' /* webpackChunkName: "components/editable-field" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../app/components/common/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const OutlineNavigation = () => import('../../app/components/common/OutlineNavigation.vue' /* webpackChunkName: "components/outline-navigation" */).then(c => wrapFunctional(c.default || c))
export const OutlineNavigationItem = () => import('../../app/components/common/OutlineNavigationItem.vue' /* webpackChunkName: "components/outline-navigation-item" */).then(c => wrapFunctional(c.default || c))
export const Table = () => import('../../app/components/common/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const TableHeaderColumn = () => import('../../app/components/common/TableHeaderColumn.vue' /* webpackChunkName: "components/table-header-column" */).then(c => wrapFunctional(c.default || c))
export const DesktopSidebar = () => import('../../app/components/layout/DesktopSidebar.vue' /* webpackChunkName: "components/desktop-sidebar" */).then(c => wrapFunctional(c.default || c))
export const LaunchNotes = () => import('../../app/components/layout/LaunchNotes.vue' /* webpackChunkName: "components/launch-notes" */).then(c => wrapFunctional(c.default || c))
export const MainHeader = () => import('../../app/components/layout/MainHeader.vue' /* webpackChunkName: "components/main-header" */).then(c => wrapFunctional(c.default || c))
export const MainMenu = () => import('../../app/components/layout/MainMenu.vue' /* webpackChunkName: "components/main-menu" */).then(c => wrapFunctional(c.default || c))
export const MobileSidebar = () => import('../../app/components/layout/MobileSidebar.vue' /* webpackChunkName: "components/mobile-sidebar" */).then(c => wrapFunctional(c.default || c))
export const MobileSidebarToggle = () => import('../../app/components/layout/MobileSidebarToggle.vue' /* webpackChunkName: "components/mobile-sidebar-toggle" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../app/components/layout/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../app/components/layout/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const SecondaryMenu = () => import('../../app/components/layout/SecondaryMenu.vue' /* webpackChunkName: "components/secondary-menu" */).then(c => wrapFunctional(c.default || c))
export const SideNavigation = () => import('../../app/components/layout/SideNavigation.vue' /* webpackChunkName: "components/side-navigation" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../app/components/layout/UserMenu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))
export const VerifyEmail = () => import('../../app/components/layout/VerifyEmail.vue' /* webpackChunkName: "components/verify-email" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
